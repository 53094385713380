import React from "react"
import { useState } from "react"
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

const Recorder = (props: any) => {

    const [state, setState] = useState<any>(null)
    const [recording, setRecording] = useState<any>(null)

    const start = () => {
        setState(
            RecordState.START
        )
    }

    const stop = () => {
        setState(
            RecordState.STOP
        )
    }

    //audioData contains blob and blobUrl
    const onStop = (audioData: any) => {
        setRecording(audioData)

        props.onRecorderStop(audioData)
    }

    return (
        <React.Fragment>
            <AudioReactRecorder state={state} onStop={onStop} canvasWidth="400" canvasHeight="75" />
            <div>
                <button onClick={start}>Record audio</button>
                <button onClick={stop}>Stop Recording</button>
            </div>
            <audio controls src={recording?.url}></audio>
        </React.Fragment>
    )
}

export default Recorder