import { Grid, Link, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

const apiUrl = `${process.env.REACT_APP_API_URL}`

function Items() {

    const [state, setState] = useState<any>([])

    useEffect(() => {
        fetch(`${apiUrl}admin/items`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(result)
                }
            )
    }, [])

    const items = state.map((item: any, index: number) => {
        return (
            <Grid item xs={12} md={4} lg={3} key={index}>
                <Link href={`/edit-item/${item.Id}`} color="inherit">
                        {item.english}
                </Link>
            </Grid>
        )
    });

    return (
        <div>
            <h1>Items</h1>
            <a href="new-item">New Item</a>
            {items}
        </div>
    );
}

export default Items;
