import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Site from './components/site';
import { CssBaseline } from '@mui/material';
import SignIn from './components/auth/signin'

function App() {
    return (
        <div>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    <Route path={`/signin`} component={SignIn}></Route>
                    <Route path={`/`} component={Site} ></Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
