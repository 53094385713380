import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { TextField, Button, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useHistory } from "react-router-dom";
import Recorder from "./recorder";

const axios = require('axios').default;

const apiUrl = `${process.env.REACT_APP_API_URL}`

const CreateItem = () => {

    const history = useHistory();

    const [state, setState] = useState({
        english: '',
        myanmar: '',
        pronunciation: '',
        isOnline: false,
        audio: '',
        sortkey: '',
    });

    const [categories, setCategories] = useState<any>([])
    const [selectedCategory, setSelectCategory] = useState<any>([])

    useEffect(() => {
        fetch(`${apiUrl}admin/categories`)
            .then(res => res.json())
            .then(
                (result) => {

                    let categoriesOptions: any = [];
                    result.forEach(function (item: any) {
                        categoriesOptions.push({ value: item.Id, label: item.name });
                    });

                    setCategories(categoriesOptions)
                }
            )
    }, [])

    const handleChangeText = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const { english, myanmar, pronunciation, isOnline, audio, sortkey } = state;

        if (!english) return;

        const item = {
            english,
            myanmar,
            pronunciation,
            isOnline,
            audio,
            categoryId: selectedCategory.value,
            sortkey
        };

        let data = new FormData()

        data.append('english', item.english)
        data.append('myanmar', item.myanmar)
        data.append('pronunciation', item.pronunciation)
        data.append('isOnline', item.isOnline.toString())
        data.append('audio', recording?.blob)
        data.append('categoryId', item.categoryId)
        data.append('sortkey', item.sortkey)

        let config = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        axios
            .post(`${apiUrl}admin/item/create`, data, config)
            .then(() => {
                console.log('Item Created')
                history.push("/items")
            })
            .catch((err: any) => {
                console.error(err);
            });
    };

    const handleDropDownChange = (newValue: any) => {
        setSelectCategory(newValue)
    }

    const [recording, setRecording] = useState<any>(null)
    const onRecorderStop = (e: any) => {
        setRecording(e)
    }

    return (
        <React.Fragment>
            <h1>New Item</h1>
            <FormGroup>
                <Select
                    placeholder='category'
                    value={selectedCategory}
                    onChange={handleDropDownChange}
                    options={categories}>
                </Select>

                <TextField
                    name={`english`}
                    label={`english`}
                    placeholder={`english`}
                    value={state.english}
                    onChange={handleChangeText} />

                <TextField
                    name={`myanmar`}
                    label={`myanmar`}
                    placeholder={`myanmar`}
                    value={state.myanmar}
                    onChange={handleChangeText} />

                <TextField
                    name={`pronunciation`}
                    label={`pronunciation`}
                    placeholder={`pronunciation`}
                    value={state.pronunciation}
                    onChange={handleChangeText} />

                <TextField
                    name={`sortkey`}
                    label={`sortkey`}
                    placeholder={`sortkey`}
                    value={state.sortkey}
                    onChange={handleChangeText} />

                <FormControlLabel control={<Switch />} label="online/offline" />

                <Recorder onRecorderStop={onRecorderStop}></Recorder>

                <Button variant="contained" onClick={handleSubmit}>Save</Button>
            </FormGroup>
        </React.Fragment>
    )
}

export default CreateItem
