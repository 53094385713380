import { Grid, Paper, Box, Avatar, Typography, TextField, Button, Link } from '@mui/material';
import React, { useState } from 'react';
import CognitoService from './cognitoService';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Unsplash from './unsplash'

const SignIn = () => {

    const [form, setForm] = useState<any>(null);

    let signin = () => {
        if (!(form.email && form.password)) {
            alert('all the fields are required')
            return;
        }

        CognitoService.login(form.email, form.password)
    }

    let handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Unsplash></Unsplash>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={signin}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SignIn