import React from "react";

const Home = () => {
    return (
        <React.Fragment>
            <h1>Home</h1>
        </React.Fragment>
    );
  }

export default Home;
