import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
const axios = require('axios').default;

function EditCategory() {

    const { id }: any = useParams();

    const apiUrl = `${process.env.REACT_APP_API_URL}`
    const [category, setCategory] = useState<any>([])
    
    const history = useHistory();

    useEffect(() => {
        fetch(`${apiUrl}admin/category/${id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setCategory(result)
                }
            )
    }, [])

    const handleDelete = (id: any) => {
        let isConfirm: any = window.confirm("are you sure?");

        if(isConfirm == true) {
            axios.post(`${apiUrl}admin/category/delete/${id}`)
            .then(function (response: any) {
                history.push('/categories');
            })
            .catch(function (error:any) {
                alert('error:' + error);
                console.log(error);
            });
        }
    }

    return (
        <React.Fragment>
            <h1>Edit Category</h1>
            <div>
                {category.name}
            </div>
            <div>
                {category.icon}
            </div>
            <div>
                {category.sortkey}
            </div>
            <div>
                <Button variant="contained" onClick={() => handleDelete(category.Id)}>Delete</Button>
            </div>
        </React.Fragment>
    )
}

export default EditCategory