import React from "react";
import { FormGroup, TextField, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_API_URL}`

const CreateCategory = () => {

    const history = useHistory();

    const [state, setState] = React.useState({
        icon: '',
        name: '',
        sortkey: '',
    });

    const handleChangeText = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const { name, icon, sortkey } = state;

        const category = {
            name, icon, sortkey
        };

        axios
            .post(`${apiUrl}admin/category/create`, category)
            .then(() => {
                console.log('Category Created')
                history.push("/categories")
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <React.Fragment>
            <h1>New Category</h1>
            <FormGroup>
                <TextField
                    name={`name`}
                    label={`name`}
                    placeholder={`name`}
                    value={state.name}
                    onChange={handleChangeText} />

                <TextField
                    name={`icon`}
                    label={`icon`}
                    placeholder={`icon`}
                    value={state.icon}
                    onChange={handleChangeText} />

                <TextField
                    name={`sortkey`}
                    label={`sortkey`}
                    placeholder={`sortkey`}
                    value={state.sortkey}
                    onChange={handleChangeText} />

                <Button variant="contained" onClick={handleSubmit}>Save</Button>
            </FormGroup>
        </React.Fragment>
    );
}

export default CreateCategory;
