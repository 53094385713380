import { Link } from "@mui/material";
import React, { useEffect, useState } from "react";

const apiUrl = `${process.env.REACT_APP_API_URL}`

function Categories() {

    const [state, setState] = useState<any>([])

    useEffect(() => {
        fetch(`${apiUrl}admin/categories`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(result)
                }
            )
    }, [])

    const categories = state.map((item: any, index: number) => {
        return (
            <div key={index}>
                <Link href={`/edit-category/${item.Id}`} color="inherit">
                    {item.name}
                </Link>
            </div>
        )
    });

    return (
        <React.Fragment>
            <div>
                <h1>Categories</h1>
                <a href="new-category">New Category</a>
                {categories}
            </div>
        </React.Fragment>
    );
}

export default Categories;
