import React from 'react'
import { NavLink } from 'react-router-dom'
import { AppBar, Toolbar, Typography, Link } from '@mui/material'

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from './auth/config';

const Nav = () => {

    const signOut = () => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();

        document.location.href = "/";
    }

    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        <NavLink
                            to={'/'}
                        >
                            Speak Myanmar Admin
                        </NavLink>
                    </Typography>
                    <nav>
                        <NavLink
                            to={'/categories'}
                        >
                            <Link
                                variant="button"
                                color="text.primary"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Categores
                            </Link>
                        </NavLink>
                        <NavLink
                            to={'/items'}
                        >
                            <Link
                                variant="button"
                                color="text.primary"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Items
                            </Link>
                        </NavLink>
                        {true && (
                            <Link
                                onClick={signOut}
                                variant="button"
                                color="text.primary"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Logout
                            </Link>
                        )}
                    </nav>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default Nav