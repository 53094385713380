import * as React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Categories from './categories';
import CreateCategory from './create-category';
import CreateItem from './create-item';
import EditCategory from './edit-category';
import EditItem from './edit-item';
import Home from './home';
import Items from './items';
import Nav from './nav';

function Site() {

    return (
        <BrowserRouter>
            <Nav></Nav>
            <Container component="main" maxWidth="xs">
                <Switch>
                    <Route exact path={'/items'} component={Items} ></Route>
                    <Route exact path={'/categories'} component={Categories} ></Route>
                    <Route exact path={'/new-category'} component={CreateCategory} ></Route>
                    <Route exact path={'/new-item'} component={CreateItem} ></Route>
                    <Route exact path={'/edit-category/:id'} component={EditCategory} ></Route>
                    <Route exact path={'/edit-item/:id'} component={EditItem} ></Route>
                    <Route exact path={'/'} component={Home} ></Route>
                </Switch>
            </Container>
        </BrowserRouter>
    );
}

export default Site;