import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Select from 'react-select';
import { TextField, Button, FormGroup } from '@mui/material';
import Recorder from "./recorder";

const axios = require('axios').default;

const EditItem = () => {

    const apiUrl = `${process.env.REACT_APP_API_URL}`

    const [state, setState] = useState({
        Id: '',
        english: '',
        myanmar: '',
        pronunciation: '',
        isOnline: false,
        audio: '',
        sortkey: '',
    });

    const { id }: any = useParams();

    const history = useHistory();

    const [categories, setCategories] = useState<any>([])
    const [selectedCategory, setSelectCategory] = useState<any>([])

    useEffect(() => {
        fetch(`${apiUrl}admin/categories`)
            .then(res => res.json())
            .then(
                (result) => {
                    let categoriesOptions: any = [];
                    result.forEach(function (item: any) {
                        categoriesOptions.push({ value: item.Id, label: item.name });
                    });

                    setCategories(categoriesOptions)

                    fetch(`${apiUrl}admin/item/${id}`)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setState(result)
                                const selectedCategory = categoriesOptions.filter((cat: any) => cat.value == result.categoryId)
                                setSelectCategory(selectedCategory)
                            }
                        )
                }
            )
    }, [])

    const handleChangeText = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const handleDropDownChange = (newValue: any) => {
        setSelectCategory(newValue)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const { Id, english, myanmar, pronunciation, isOnline, audio, sortkey } = state;

        if (!english) return;

        const item = {
            Id,
            english,
            myanmar,
            pronunciation,
            isOnline,
            audio,
            categoryId: selectedCategory.value,
            sortkey,
        };

        let data = new FormData()

        data.append('Id', item.Id)
        data.append('english', item.english)
        data.append('myanmar', item.myanmar)
        data.append('pronunciation', item.pronunciation)
        data.append('isOnline', item.isOnline.toString())
        data.append('audio', recording?.blob)
        data.append('categoryId', item.categoryId)
        data.append('sortkey', item.sortkey)

        let config = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        axios
            .post(`${apiUrl}admin/item/edit`, data, config)
            .then(() => console.log('Item Edited'))
            .catch((err: any) => {
                console.error(err);
            });
    };

    const handleDelete = (id: any) => {
        let isConfirm: any = window.confirm("are you sure?");

        if (isConfirm == true) {
            axios.post(`${apiUrl}admin/item/delete/${id}`)
                .then(function (response: any) {
                    alert('success')
                    history.push('/categories');
                })
                .catch(function (error: any) {
                    alert('error:' + error);
                    console.log(error);
                });
        }
    }

    const [recording, setRecording] = useState<any>(null)
    const onRecorderStop = (e: any) => {
        setRecording(e)
    }

    return (
        <React.Fragment>
            <h1>Edit Item</h1>
            <FormGroup>
                <Select
                    placeholder='category'
                    value={selectedCategory}
                    onChange={handleDropDownChange}
                    options={categories}>
                </Select>

                <TextField
                    name={`english`}
                    label={`english`}
                    placeholder={`english`}
                    value={state.english}
                    onChange={handleChangeText} />

                <TextField
                    name={`myanmar`}
                    label={`myanmar`}
                    placeholder={`myanmar`}
                    value={state.myanmar}
                    onChange={handleChangeText} />

                <TextField
                    name={`pronunciation`}
                    label={`pronunciation`}
                    placeholder={`pronunciation`}
                    value={state.pronunciation}
                    onChange={handleChangeText} />

                <TextField
                    name={`sortkey`}
                    label={`sortkey`}
                    placeholder={`sortkey`}
                    value={state.sortkey}
                    onChange={handleChangeText} />

                <audio src={state.audio} controls></audio>

                <Recorder onRecorderStop={onRecorderStop}></Recorder>

                <Button variant="contained" onClick={handleSubmit}>Save</Button>
            </FormGroup>
            <div>
                <Button variant="contained" onClick={() => handleDelete(state.Id)}>Delete</Button>
            </div>
        </React.Fragment>
    )
}

export default EditItem